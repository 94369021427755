/* ##############################################

Código loader Spinner Puntaje fuera de la aplicación

############################################## */

.outside-loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 60px;
    height: 60px;
}

.spinner {
    margin: 0 auto;
    font-size: 8px;
    position: relative;
    text-indent: -9999em;
    border: 5px solid #005b82;
    border-left-color: rgba(169, 169, 169, 0.2);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spin 1.1s infinite linear;
    animation: spin 1.1s infinite linear;
}
.spinner,
.spinner:after {
    border-radius: 50%;
    width: 8em;
    height: 8em;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
